export class ProductActions {
  constructor() {
    this.addToWishList();
    this.moreProductsAction();

    if (window.paged) {
      this.paged = window.paged+1;
    } else {
      this.paged = 2;
    }
  }

  moreProductsAction() {
    $(document).on('click', '.products__content-pagination .more', (event) => {
      this.moreProducts(event);
    })
  }

  /* global JVMWooCommerceWishlistJSParams, JVMWooCommerceWishlist, Cookies */
  addToWishList() {
    $(document).on('click', '.add_favorite', function () {
      let productId = $(this).data().productId;
      let $this = $(this);

      $.ajax({
        url: window.ajaxurl,
        method: 'POST',
        data: {
          action: 'updateWishList',
          product_id: productId,
          userId: JVMWooCommerceWishlistJSParams.userId,
        },
        success: function (response) {
          if (response.success && response.data) {
            $('.header__basket-num span').text(response.data.count);

            if (response.data.is_exist) {
              $this.removeClass('active');
            }

            if (JVMWooCommerceWishlistJSParams.userId === '0') {
              let cookieName = JVMWooCommerceWishlist.cookieName;
              Cookies.set(cookieName, response.data.wishlist.join(','), {path: '/', expires: 7});
            }
          }
        }
      });
    });
  }

  moreProducts(event) {
    let self = this;

    event.target.className = event.target.className + ' moreLoader'

    $.ajax({
      url: window.ajaxurl,
      method: 'post',
      data: {
        action: 'moreProducts',
        paged: this.paged,
        categoryObject: window.queryObject,
        query: window.query || [],
        link: window.link || ''
      },
      success: function (data) {
        if (data.data.products) {
          self.paged += 1;
          $('.listProduct__items .row').append(data.data.products);
          event.target.className = event.target.className.replace(' moreLoader', '');
          $('.pagination').html(data.data.pagination)
        } else {
          $(event.target).hide();
        }
      }
    })
  }
}

$(document).ready(function () {
  new ProductActions();
});
