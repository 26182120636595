export class headerNew {

  constructor() {
    this.headerDropdown();
    this.burgerMenu();
    this.headerHelp();
    this.headerCall();
    this.burgerCatalog();
    // this.catalogMobile();
  }

  headerDropdown() {
    $(document).on('click', '.header__data-help', function () {
      $('.header__data-help ul').slideToggle();
      $(this).toggleClass('active');
    });

    $(document).on('click', '.header__data-city .city span', function () {
      $('.header__data-city .listCity').slideToggle();
      $(this).toggleClass('active');
    });

    $(document).mouseup(function (e) {
      let city = $(".header__data-city");
      let help = $(".header__data-help");
      if (!city.is(e.target)
        && city.has(e.target).length === 0) {
        city.find('.listCity').hide();
      }

      if (!help.is(e.target)
        && help.has(e.target).length === 0) {
        help.find('ul').hide();
        $('.header__data-help').removeClass('active');
      }
    });
  }

  headerCall() {
    $(document).on('click', '.header__contact-tel .title', function () {
      $('.formCall').slideToggle();
    });

    $(document).mouseup(function (e) {
      let call = $(".header__contact-tel");
      if (!call.is(e.target)
        && call.has(e.target).length === 0) {
        call.find('.formCall').hide();
      }
    });

    let formCall = document.querySelector('.formCall .wpcf7');

    formCall.addEventListener('wpcf7mailsent', function () {
      $('.formCall .wpcf7').css('display', 'none');
      $('div.formCall__text').toggleClass('active')
    }, false);
  }

  burgerMenu() {
    $(document).on('click', '.mobile__burger-button, .header__close', function () {
      $('.mobile__burger-list').toggleClass('active');
    });
  }

  headerHelp() {
    $(document).on('click', '.mobile__burger-list.active .wrap .help', function () {
      $(this).find('ul').slideToggle();
      $(this).toggleClass('active');
    });
    $(document).mouseup(function (e) {
      let div = $(".mobile__burger-list.active .wrap .help");
      if (!div.is(e.target)
        && div.has(e.target).length === 0) {
        div.find('ul').hide();
      }
    });
  }

  burgerCatalog() {
    $(document).on('click', '.mobile__burger-list .wrap .catalog span.catalog, .catalog__list-title', function () {
      $('div.catalog__list').toggleClass('active');
    });

    $('.catalog__list .product_categories ul li.children svg').each(function () {
      $(this).click(function () {
        $(this).parent().find('ul').slideToggle('active');
      });
      $('.li.children ul').slideToggle('active');
    });
  }

  catalogMobile() {
    $(document).on('click', '.product_categories ul li.children img', function () {
      $(this).parent().find('ul').slideToggle();
      $(this).parent().toggleClass('active');
    });
  }
}

$(document).ready(function () {
  new headerNew();
});
