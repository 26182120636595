import {Translate} from "../../util/Translate";
import {parseResult} from "../../util/ParseData";

class Account {
  constructor() {
    this.changeDataInput();
    this.userChangePassword();
    this.resetPassword();
  }

  resetPassword() {
    $(document).on('submit', '#restorePassword', function (e) {
      e.preventDefault();

      let serializeData = $(this).serializeArray();
      let data = parseResult(serializeData);

      let request = {
        action: 'resetPassword',
        data: data
      };

      $.ajax({
        url: window.ajaxurl,
        data: request,
        method: 'post',
        success: function (res) {
          console.log(res);
        }
      });

    });
  }

  changeDataInput() {
    $(document).on('click', '.editActions__wrap', function () {
      let inputs = $(this).parent().parent().find('div.accountData__item-input span');
      let textSave = Translate('text_save');
      $(this).addClass('active');
      $('.accountData__item.input').addClass('active');
      $('.editActions__wrap-title').text(textSave);
      $(".editActions__wrap-img img").attr("src", "/wp-content/themes/babybox/resources/assets/img/newPage/save.svg}");
      inputs.each(function () {
        let name = $(this).data().name;
        let input = $('<input name="' + name + '" type="text">').val($(this).text());

        $(this).replaceWith(input);
      });
    });

    $(document).on('click', '.editActions__wrap.active', function () {
      let $this = $(this);
      let $name = $('input[name=name]').val()
        .trim()
        .split(' ');
      let first_name = $name[0];
      let last_name = $name[1];

      $.ajax({
        url: window.ajaxurl,
        data: {
          action: 'saveUserData',
          data: {
            email: $('input[name=email]').val(),
            last_name: last_name,
            first_name: first_name,
            address: $('input[name=address]').val(),
            phone: $('input[name=phone]').val(),
          }
        },
        method: 'post',
        success: function () {
          let getAllInputs = $this.parent().parent().find("input[type='text']");
          $this.removeClass('active');
          let textEdit = Translate('account_edit');
          $('.accountData__item.input').removeClass('active');
          $('.editActions__wrap-title').text(textEdit);
          $(".editActions__wrap-img img").attr("src", "/wp-content/themes/babybox/resources/assets/img/newPage/edit.svg");

          getAllInputs.each(function () {
            let $name = $(this).attr('name');
            let replaceInput = $('<span data-name="' + $name + '">' + $(this).val() + '</span>')

            $(this).replaceWith(replaceInput);
          });

        }
      });

    });
  }

  userChangePassword() {
    let $this = this;

    $(document).on('submit', '#userChangePassword', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let changePassData = $this.parseResult(serializeData);

      if (changePassData) {
        let data = {
          action: 'restorePassword',
          data: changePassData,
        };

        $this.ajax(data).then(result => {

          if (result.data.success) {
            console.log('success')
          } else {
            console.log('error')
          }
        });
      }

    });
  }

  ajax(data) {
    return $.post(window.ajaxurl, data);
  }

  parseResult($fields) {
    let result = {};

    $.each($fields, function () {
      result[this.name] = this.value;
    });

    console.log(result);
    return result;
  }
}


$(document).ready(function () {
  new Account();
})
