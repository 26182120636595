export function Translate(slug) {
  const translates = window.site_options.translates;
  const result = translates.filter(result => result.slug === slug);

  return (result.length) ? result[0].title : ucFirst(slug.replace('_', ' '));
}

function ucFirst(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
