export class singleProduct {

  constructor() {
    this.singleSlider();
    this.selectSize();
    this.selectColor();
    this.productColor();
  }

  singleSlider() {
    let singleNav = $('.sliderNav');
    let singleSlider = $('.sliderMain__wrap-items');
    let slideCount = $('.sliderNav__item').length;

    if (singleSlider.length) {
      singleSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $('.sliderMain__prev'),
        nextArrow: $('.sliderMain__next'),
        fade: true,
        asNavFor: '.sliderNav'
      });
    }

    if (singleNav.length) (
      singleNav.slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        centerMode: true,
        focusOnSelect: true,
        centerPadding: 0,
        asNavFor: '.sliderMain__wrap-items',
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 4,
            }
          },
        ]
      })
    )

    if (slideCount <= 4) {
      singleNav.toggleClass('notransform')
    }
  }

  selectColor() {
    $(document).on('click', '.characteristics__color li span', function (){
      let variation_id = $(this).data().id;
      let variation_name = $(this).data().name;
      let price = $(this).data().price;

      $('.button__price-new .default_price').text(price);
      $('.add_to_cart_button span').attr('data-product_id', variation_id);

      $.ajax({
        url: window.ajaxurl,
        method: 'POST',
        data: {
          action: 'getVariation',
          variation_name: variation_name,
          product_id: window.productId
        },
        success: function (res) {
          if (res.data && res.data.attr.attribute_pa_size) {
            let attr = res.data.attr.attribute_pa_size;
            let data = attr.map(item => {
              return {
                id: item.variation_id,
                text: item.name,
                price: item.price,
              }
            });
            console.log(data, 'data');
            let $selectSize = $('.characteristics__size-list select');
            $selectSize.empty();
            $selectSize.select2({
              data: data
            });
          }
        }
      })
    })
  }

  selectSize() {
    $(document).ready(function () {
      let $selectSize = $('.characteristics__size-list select');
      $selectSize.select2();
      $selectSize.on("change", function (e) {
        $('.add_to_cart_button span').attr('data-product_id', e.currentTarget.value);
      });
    });
  }

  productColor() {
    $(document).on('click', '.characteristics__color-list ul li', function () {
      $('.characteristics__color-list ul li').removeClass('active')
     $(this).toggleClass('active');
      $('.characteristics__color-list').addClass('active')
    });
  }
}

$(document).ready(function () {
  new singleProduct();
});
