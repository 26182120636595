import 'jquery';
import "./autoload/_bootstrap"
import 'sharer.js';
import 'izimodal/js/iziModal.min';
import 'slick-carousel/slick/slick.min';
import '@fortawesome/fontawesome-free/js/all.min';
import "imask/dist/imask.min"
import 'select2/dist/js/select2.min'
import 'masonry-layout/masonry'
import 'masonry-layout/dist/masonry.pkgd.min'
import 'magnific-popup/dist/jquery.magnific-popup'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import './classes/pages/HomePage';
import './classes/pages/ProductActions';
import './classes/pages/headerNew';
import './classes/pages/contact';
import './classes/pages/singleProduct';
import aboutUs from './routes/about';
import contacts from './routes/contacts';
import './classes/pages/Auth'
import './classes/pages/Account'
import './classes/ChooseCity'
import './classes/pages/OrderHistory'

/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  home,
  aboutUs,
  contacts
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
