export class HomePage {

  constructor() {
    this.heroSlider();
    this.categoryMain();
    this.reviewSlider();
    this.newsSlider();
    this.favorites();
    this.reviewsModal();
    this.popularSlider();
    this.brandsListSlider();
    this.complectlist();
    this.HiddenTextSeo();
    this.babyBoxSlider();
    this.promotion();
  }

  heroSlider() {
    let slider = $('.bb-slider__slider');

    if (slider.length) {
      slider.slick({
        prevArrow: '<div class="prev"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/prewArr.svg" alt=""></div>',
        nextArrow: '<div class="next"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/nextArr.svg" alt=""></div>',
        responsive: [
          {
            breakpoint: 576,
            settings: {
              arrows: false,
              dots: true,
            }
          },
        ]
      });
    }
  }

  categoryMain() {
    $('.product_categories ul li.children img').each(function () {
      $(this).click(function () {
        $(this).parent().find('ul').toggleClass('active');
        $(this).toggleClass('active');
      });
      $('.li.children ul').toggleClass('active');
      $('.product_categories ul li.children img').toggleClass('active');
    });

    $(document).mouseup(function (e) {
      let div = $(".product_categories ul li.children");
      if (!div.is(e.target)
        && div.has(e.target).length === 0) {
        div.find('ul').removeClass('active');
        div.find('img').removeClass('active');
      }
    });
  }

  reviewSlider() {
    let slider = $('.reviews__list-items');

    if (slider.length) {
      slider.slick({
        prevArrow: '<div class="prev"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/prewArr.svg" alt=""></div>',
        nextArrow: '<div class="next"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/nextArr.svg" alt=""></div>'
      });
    }
  }

  newsSlider() {
    let slider = $('.news__wrap-list');

    if (slider.length) {
      slider.slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<div class="prev"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/prewArr.svg" alt=""></div>',
        nextArrow: '<div class="next"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/nextArr.svg" alt=""></div>',
        responsive: [
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      })
    }
  }

  favorites() {
    $(document).on('click', '.favorites', function () {
      $(this).toggleClass('active');
    });
  }

  /* global wpcf7 */
  fixModalJs(modal) {
    console.log(modal, 'modal');
    setTimeout(() => {
      if (modal && modal.length > 0) {
        wpcf7.init(modal[0]);
      }
    }, 1000);
  }

  reviewsModal() {
    let reviewModal = $('.reviewsModal');
    $('.form__success').hide();
    reviewModal.iziModal();

    $(document).on('click', '.reviews__list-buttons .button', () => {
      reviewModal.iziModal('open');
      this.fixModalJs(reviewModal.find('form'));
    });

    $(document).on('click', '.form__success-close', () => {
      location.reload();
    });

    document.addEventListener('wpcf7mailsent', function () {
      $('.reviewsModal__wrap').toggleClass('active')
      $('.form__success').show();
    }, false);
  }

  popularSlider() {
    if ($(window).width() < 769) {
      let slider = $('.popular__list .row');

      if (slider.length) {
        slider.slick({
          slidesToShow: 2,
          slidesToScroll: 1,
          prevArrow: '<div class="prev"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/prewArr.svg" alt=""></div>',
          nextArrow: '<div class="next"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/nextArr.svg" alt=""></div>',
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              }
            },
          ]
        })
      }
    }
  }

  brandsListSlider() {
    if ($(window).width() < 769) {
      let slider = $('.brands__list ul');

      if (slider.length) {
        slider.slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          prevArrow: '<div class="prev"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/prewArr.svg" alt=""></div>',
          nextArrow: '<div class="next"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/nextArr.svg" alt=""></div>',
          responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              }
            },
          ]
        })
      }
    }
  }

  complectlist() {
    $(document).on('click', '.complect__show, .complect__list-hide', function () {
      $('.complect__list').slideToggle();
    });
  }

  HiddenTextSeo() {
    $(document).ready(function () {
      $('.info_toggle').click(function () {
        let infoText = $('.info__text');
        infoText.slideToggle();
        infoText.toggleClass('active');
        let infoToggle = $('.info_toggle');

        if (infoText.hasClass('active')) {
          infoToggle.html('Приховати інформацію');
        } else {
          infoToggle.html('Читати інформацію');
        }
        return false;
      });
    });
  }

  babyBoxSlider() {
    let slider = $('.babybox__slider');

    if (slider.length) {
      slider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<div class="prev"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/prewArr.svg" alt=""></div>',
        nextArrow: '<div class="next"><img src="/wp-content/themes/babybox/resources/assets/img/newPage/nextArr.svg" alt=""></div>',
        appendArrows: $('.babyboxArrow'),
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
            }
          },
        ]
      })
    }
  }

  promotion() {
    let promotionList = $('.promotions__list');
    promotionList.children().slice(7).toggleClass('active')

    $(document).on('click', '.promotions__button', function () {
      promotionList.children().slice(7).toggleClass('active')
      $(this).css('display', 'none')
    });
  }
}

$(document).ready(function () {
  new HomePage();
});
