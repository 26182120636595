import {searchCity} from "./NovaPost";

class ChooseCity {
  constructor() {
    this.getCity();
    this.activeCity();

    if (localStorage.city) {
      $('.header__data-city .city span').text(localStorage.city)
    }
  }

  getCity() {
    $(document).on('keyup', '.listCity__search input', function () {
      let val = $(this).val();
      let $this = $(this);

      searchCity(val).then((res) => {
        if (res.data[0] && res.data[0].Addresses) {
          let address = res.data[0].Addresses
          let html = '<ul>';

          for (let item of address) {
            html += `<li data-ref="${item.DeliveryCity}"><span>${item.MainDescription}</span></li>`
          }

          html += '</ul>'

          $this.parent().find('.listCity__search_list').html(html);
        }
      })
    })
  }

  activeCity() {
    const $this = this;
    let currentCity = $('.listCity__items ul li');
    let activeData = [];

    currentCity.each(function () {
      if ($(this).data().ref === localStorage.cityRef) {
        $(this).addClass('active');
        activeData.push('active');
      }
    });

    if (!activeData.length && !localStorage.city) {
      let firstElement = currentCity.first();
      firstElement.addClass('active');
      $this.setCityStorage(firstElement.find('span').text(), firstElement.data().ref);
    }

    $(document).on('click', '.listCity__search_list li, .listCity__items li span', function () {
      let city = $(this).text();
      let cityRef = $(this).parent().data().ref;
      $this.setCityStorage(city, cityRef);
      $('.header__data-city .city span').text(city)
      $('.listCity').hide();
      currentCity.removeClass('active');
      $(this).parent().toggleClass('active');
    });
  }

  setCityStorage(city, cityRef) {
    localStorage.city = city;
    localStorage.cityRef = cityRef;
  }
}

new ChooseCity();
