export default {
  init() {
    this.slickSliderSimpleArticle();
    this.slickSliderSingle();
    this.slickSliderGallery();
    this.faq();
    this.slickSliderBlog();
    this.productScroll();
    this.menu();
    // this.wowAnimation();
    // this.categoryMenu();
    this.navProductMenu();
    this.magnificPopupInit();
  },

  navProductMenu() {
    $(document).on('click', '.nav-tabs .nav-link', function (e) {
      e.preventDefault();
      $('.nav-link').removeClass('active');
      $(this).addClass('active');
      let id = $(this).attr('href');
      $('.tab-content .tab-pane')
        .removeClass('show')
        .removeClass('active');
      $(`${id}`).addClass('show').addClass('active')
    })
  },
  slickSliderSimpleArticle() {
    let $slider = $('.article__slider-wrap');
    if ($slider.length) {
      $slider.slick({
        arrows: true,
        centerPadding: "0px",
        dots: true,
        slidesToShow: 1,
        infinite: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
        nextArrow: '<button type = "button" class = "slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
        // appendArrows: $('.mainBox__button'),
        responsive: [
          {
            breakpoint: 576,
            settings: {
              arrows: false,
            }
          },
        ]

      });
    }
    $('.mainBox__button button').click(function (e) {
      $('.smainBox__button button').removeClass('active')
      e.preventDefault();
      $(this).addClass('active')
    });
  },

  slickSliderSingle() {
    let $slider = $('.singleProduct__items-image ul');
    if ($slider.length) {
      $slider.slick({
        arrows: true,
        centerPadding: "0px",
        dots: true,
        slidesToShow: 1,
        infinite: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
        nextArrow: '<button type = "button" class = "slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
        appendDots: $('.singleProduct__button-dots'),
        appendArrows: $('.singleProduct__button-next'),
        responsive: [
          {
            breakpoint: 576,
            settings: {
              arrows: false,
            }
          },
        ]

      });
    }
    $('.mainBox__button button').click(function (e) {
      $('.smainBox__button button').removeClass('active')
      e.preventDefault();
      $(this).addClass('active')
    });

    let dotsReview = $('.singleProduct__items-image ul div.slick-list .slick-track li');
    if (dotsReview.length <= 1) {
      $('.singleProduct__button-dots').css('display', 'none')
    } else {
      $('.singleProduct__button-dots').css('display', 'block')
    }
  },

  slickSliderGallery() {
    let $slider = $('.characteristicSlider');
    if ($slider.length) {
      $slider.slick({
        arrows: true,
        centerPadding: "0px",
        dots: false,
        slidesToShow: 1,
        infinite: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
        nextArrow: '<button type = "button" class = "slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
        appendArrows: $('.characteristicButton'),
      });
    }
  },

  faq() {
    $('.question__items .item').each(function () {
      $(this).find('.accordion').click(function () {
        $(this).parent().find('.panel').slideToggle();
        $(this).parent().toggleClass('active');
      })
    });
  },

  barMovingCurrentMenu(width, left) {
    let bar = document.createElement('span');
    bar.style.width = width + 'px';
    bar.style.left = left + 'px';
  },

  slickSliderBlog() {
    let $slider = $('.blogSlider ul');
    if ($slider.length) {
      $slider.slick({
        arrows: true,
        centerPadding: "0px",
        dots: true,
        slidesToShow: 3,
        infinite: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
        nextArrow: '<button type = "button" class = "slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',
        appendDots: $('.slidePoint'),
        appendArrows: $('.slideButton'),
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
        ]
      });
    }
    $('.slick-arrow').click(function (e) {
      $('.slick-arrow').removeClass('active')
      e.preventDefault();
      $(this).addClass('active')
    });

    let dotsBlog = $('.blogSlider ul .slick-list .slick-track li');
    if (dotsBlog.length <= 3) {
      $('.blogMain__slider-button .slidePoint').css('display', 'none')
    } else {
      $('.blogMain__slider-button .slidePoint').css('display', 'block')
    }
  },

  productScroll() {
    $(".equipment__all a").click(function () {
      $('html, body').animate({
        scrollTop: $("#equipment__all").offset().top
      }, 1000);
    });
  },

  menu() {
    $('.burger, .overlay').click(function () {
      $('.burger').toggleClass('clicked');
      $('.overlay').toggleClass('show');
      $('nav').toggleClass('show');
      $('body').toggleClass('overflow');
      if ($('div.burger').hasClass('clicked')) {
        setTimeout(function () {
          $('.headerLogo__logo svg path').css('fill', 'white');
          $('.banner').css('position', 'fixed');
        }, 400);
      } else {
        $('.banner').css('position', 'relative');
        $('.headerLogo__logo svg path').css('fill', '#9086BB')
      }
    });
  },
  magnificPopupInit() {
    $('.magnific-popup').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true
      }
    });
  }
};
