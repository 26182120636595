import IMask from 'imask';
import {parseResult} from "../../util/ParseData";

class Auth {
  constructor() {
    this.login();
    this.registration();
    this.forgotPassword();
    this.changePassword();
    this.changeFormPass();
    this.inputMask();
    this.backPage();
  }

  login() {
    let $this = this;

    $(document).on('submit', '#login', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let loginData = parseResult(serializeData);
      let isValid = $this.validationLogin(loginData);
      if (loginData && isValid) {
        let data = {
          action: 'ajaxLogin',
          data: loginData,
        };

        $this.ajax(data).then(result => {
          if (result.data.success) {
            location.href = result.data.redirect;
          } else {
            $(this).parent().find('.error').text(result.data.message);
          }
        });
      }
    });
  }

  registration() {
    let $this = this;

    $(document).on('submit', '#registration', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let registrationData = parseResult(serializeData);
      let isValid = $this.validation(registrationData);

      if (registrationData && isValid) {
        let data = {
          action: 'ajaxRegistration',
          data: registrationData,
        };

        $this.ajax(data).then(result => {
          if (result.data.success) {
            $('a.cabinet').attr('href', result.data.redirect);
            $('.forms__success').toggleClass('active')
          } else {
            $(this).parent().find('.error').text(result.data.message);
          }
        });
      }
    });
  }

  forgotPassword() {
    let $this = this;

    $(document).on('submit', '#forgotPassword', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let forgotData = parseResult(serializeData);
      if (forgotData) {
        let data = {
          action: 'restorePassword',
          email: forgotData.email,
          isCheckEmail: true,
        };

        $this.ajax(data).then(result => {
          console.log(result)
          if (result.data.success) {
            $('.forms__forgot').toggleClass('active');
            $(this).parent().toggleClass('active');
            $(this).addClass('active')
          } else {
            $(this).parent().find('.error').text(result.data.message);
          }
        });
      }

    });
  }

  changePassword() {
    let $this = this;

    $(document).on('submit', '#forgotPassword', function (e) {
      e.preventDefault();
      let serializeData = $(this).serializeArray();
      let forgotData = parseResult(serializeData);

      if (forgotData) {
        let data = {
          action: 'restorePassword',
          password: forgotData.password,
          email: forgotData.email,
        };

        $this.ajax(data).then(result => {
          console.log(result)
          if (result.data.success) {
            location.href = result.data.redirect;
          } else {
            console.log('error')
          }
        });
      }

    });
  }

  ajax(data) {
    return $.post(window.ajaxurl, data);
  }

  changeFormPass() {
    $(document).on("click", ".forgotPass span", function () {
      $('.forms__main').toggleClass('active');
      $('.forms__forgot').toggleClass('active');
    });
  }

  validation(data) {
    let errors = [];
    let index = -1;
    let inputTel = $('input[type="tel"]');
    let errorText = [];

    if (data.phone.length === 19) {

      index = errors.indexOf('phone');
      inputTel.removeClass('error')
      if (index >= 0) {
        errors.splice(index, 1);
      }

    } else {
      errors.push('phone')
      inputTel.addClass('error')
      errorText.push('<div>Номер має відповідати формату: +380** *** ** **</div>');
    }

    if (data.email) {
      index = errors.indexOf('email');
      $('input[type="email"]').removeClass('error')
      if (index >= 0) {
        errors.splice(index, 1);
      }

    } else {
      errors.push('email')
      $('input[type="email"]').addClass('error')
      errorText.push('<div>Email має відповідати формату: "******@*******"</div>');
    }

    if (data.password) {
      index = errors.indexOf('password');
      $('input[type="password"]').removeClass('error')
      if (index >= 0) {
        errors.splice(index, 1);
      }

    } else {
      errors.push('password')
      $('input[type="password"]').addClass('error')
      errorText.push('<div>Пароль не може бути пустим</div>');
    }
    $('.error').html(errorText);
    return !errors.length;
  }

  validationLogin(data) {
    let errors = [];
    let index = -1;

    if (data.email) {
      index = errors.indexOf('email');
      $('input[type="email"]').removeClass('error')
      if (index >= 0) {
        errors.splice(index, 1);
      }

    } else {
      errors.push('email')
      $('input[type="email"]').addClass('error')
      $('.error').text('Email має відповідати формату: "******@*******" ');
    }

    return !errors.length;
  }

  inputMask() {
    const phoneMaskSelector = "input[type='tel']";
    const phoneMaskInputs = document.querySelectorAll(phoneMaskSelector);
    const masksOptions = {
      phone: {
        mask: '+{38} (000) 00 00 000'
      }
    };

    for (const item of phoneMaskInputs) {
      new IMask(item, masksOptions.phone);
    }
  }

  backPage() {
    $(document).on("click touchstart", ".auth__back a", function () {
      window.history.go(-1);
      return false;
    });
  }
}

$(document).ready(function () {
  new Auth();
})
