var novapostApiUrl = 'https://api.novaposhta.ua/v2.0/json/';

export function searchCity(city) {
  let args = {
    "apiKey": window.NovapostToken,
    "modelName": "Address",
    "calledMethod": "searchSettlements",
    "methodProperties": {
      "CityName": city,
      "Limit": 30
    }
  };

  return $.post(novapostApiUrl, JSON.stringify(args))
}
