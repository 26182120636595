class OrderHistory {
  constructor() {
    this.reOrder();
    this.CancelOrder();
  }

  reOrder() {
    $(document).on('click', '.reOrder', function (e) {
      e.preventDefault();

      let data = {
        action: 'reOrder',
        order_id: $(this).data().orderId
      };

      $.ajax({
        url: window.ajaxurl,
        method: 'POST',
        data: data,
        success: function (res) {

          if (res.success) {
            location.href = '/cart/';
          }
        }
      })
    })
  }

  CancelOrder() {
    $(document).on('click', '.CancelOrder', function (e) {
      e.preventDefault();

      let data = {
        action: 'cancelOrder',
        order_id: $(this).data().orderId
      }

      $.ajax({
        url: window.ajaxurl,
        method: 'POST',
        data: data,
        success: function (res) {
          if (res.success) {
            location.reload();
          }
        }
      })
    })
  }
}

new OrderHistory();
