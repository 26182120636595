export class contact {

  constructor() {
    this.sendForm();
  }

  // /* global wpcf7 */
  // fixModalJs(modal) {
  //   setTimeout(() => {
  //     if (modal.length > 0) {
  //       wpcf7.init(modal[0]);
  //     }
  //   }, 0);
  // }

  sendForm() {
    $(".wpcf7").on('wpcf7mailsent', function () {
      $('.postContent').show();
      let $questionForm = $('.questionContent__form');
      let $form = $('.form .form__content');
      $questionForm.hide();
      $form.hide();
      $(document).on('click', '.postContent__wrap-button.price', function () {
        $questionForm.show();
        $form.show();
      })
    });
  }
}

$(document).ready(function () {
  new contact();
});
