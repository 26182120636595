import {maps2gis} from '../classes/maps2gis'

export default {
  init() {
    this.map();
  },
  map() {
    let map = window.contact_map || [];
    let coordinates = [{
      icon: map.icon,
      lat: map.lat,
      lng: map.lng,
      address: map.address,
      title: map.title,
      zoom: 18,
      c_lat: map.c_lat,
      c_lng: map.c_lng,
      id: 1
    }];
    new maps2gis('#map', coordinates);
  }
};
